<template>
  <div class='news'>
    <el-empty description="暂无内容"></el-empty>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.news {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}
</style>
